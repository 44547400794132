import React from "react";
import { Modal } from "react-bootstrap";
import { selectLoader } from "../utils/Helpers";

const ModalCenter = ({ show, closeModal, children, headerTitle, extraClass='' }) => {
  return (
    <div>
      <Modal
        show={show}
        // onHide={closeModal}
        dialogClassName=""
        centered
        className={`deletModal ${extraClass}`}
      >
        <Modal.Header closeButton={true} onClick={closeModal}>
          {headerTitle}
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {/* <button className="btn-black" type="submit">Add</button>    */}
      </Modal>
    </div>
  );
};

export default ModalCenter;
