import React, { useEffect, useState } from "react";
import barcode1 from "../assets/img/barcode1-red.svg";
import barcode2 from "../assets/img/barcode2-red.svg";
import barcode3 from "../assets/img/barcode1-blue.svg";
import barcode4 from "../assets/img/barcode2-blue.svg";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { adjustDate, formatDate } from "../utils/Helpers";
const UpdateDaysSlider = ({
  updatedDay,
  projectDetails,
  progress = 100,
  setUpdatedDay,
}) => {
  const [currentDay, setCurrentDay] = useState(30); // Default value set to 0 (index of 0 in days array)
  const days = Array.from({ length: 61 }, (_, i) => i - 30); // Create days array from -30 to +30

  const visibleCount = 5; // Number of numbers visible at a time (odd for centering)

  const handleIncrease = () => {
    setCurrentDay((prev) => Math.min(prev + 1, days.length - 1));
  };

  const handleDecrease = () => {
    setCurrentDay((prev) => Math.max(prev - 1, 0));
  };

  // Status label based on the current day
  const getStatusLabel = () => {
    const dayValue = days[currentDay];
    if (dayValue < 0) return "Early";
    if (dayValue === 0) return "On-time";
    if (dayValue > 0) return "Delayed";

    return "Normal";
  };

  useEffect(() => {
    // if (days[currentDay]) {

    if (days[currentDay] == 0) {
      console.log(currentDay + updatedDay);

      setCurrentDay((currentDay) => currentDay + updatedDay);
    }

    // }
  }, [updatedDay]);

  useEffect(() => {
    if (days[currentDay] != NaN) {
      setUpdatedDay(days[currentDay]);
    }
  }, [currentDay]);

  // Determine the range of visible numbers centered around the currentDay
  const getVisibleNumbers = () => {
    const halfVisible = Math.floor(visibleCount / 2);
    const start = Math.max(currentDay - halfVisible, 0);
    const end = Math.min(start + visibleCount, days.length);
    const offsetStart = Math.max(
      0,
      currentDay - halfVisible - (end - start - visibleCount)
    );

    return days.slice(offsetStart, offsetStart + visibleCount);
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-lg-12">
          <h3 className="project-status-title">Project</h3>

          <div className="project-status">
            <label
              id="status-label"
              className={`status ${getStatusLabel()
                .toLowerCase()
                .replace(" ", "-")}`}
            >
              {getStatusLabel()}
            </label>

            <div className="slider-container">
              <button
                id="decrease-btn"
                className="slider-btn"
                onClick={handleDecrease}
              >
                −
              </button>

              <div className="slider-numbers">
                <div
                  className="numbers-wrapper"
                  style={{
                    transform: `translateX(0px)`, // Center the slider
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  {getVisibleNumbers().map((day, index) => (
                    <span
                      key={index}
                      className={`number ${getStatusLabel().toLowerCase()} ${
                        days[currentDay] === day ? "active" : ""
                      }`}
                      data-value={day}
                    >
                      {day}
                    </span>
                  ))}
                </div>
              </div>

              <button
                id="increase-btn"
                className="slider-btn"
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12">
          {/* <div className="projectProgress-bg">
            <div className="project-progress">
              <div className="progress-bar-container">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={progress}
                  className="slider"
                  //   onChange={handleInputbarChange}
                  style={{
                    background: `linear-gradient(to right, #14904A ${progress}%, #14904A  ${progress}%)`,

                    "--progress": `${progress}%`,
                  }}
                />

                <div
                  className="thumb"
                  style={{
                    left: `calc(${progress}% - ${
                      progress == 0 ? "0" : "30px"
                    })`,
                  }}
                >
                  {progress}%
                </div>

                <div className="progress-content">
                  <p>April 3</p>

                  <p>May 18</p>
                </div>
              </div>
              {getStatusLabel() == "Delayed" && (
                <div className="expire-container-red">
                  <div className="expire-progress">
                    <span>+{days[currentDay]}d</span>
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13L7 7L1 1"
                        stroke="#FF0000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <p>Exp: May 20</p>
                </div>
              )}

              {getStatusLabel() == "Early" && (
                <div className="expire-container-blue">
                  <div className="expire-progress">
                    <span>{days[currentDay]}d</span>

                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13L7 7L1 1"
                        stroke="#218BC8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <p>Exp: May 20</p>
                </div>
              )}
            </div>
          </div> */}
          <div className="processBg-cover">
            <div className="process-content-cover">
              <div className="process-cover" id="process-tour">
                <p
                  style={{
                    position: "absolute",
                    left: `${projectDetails?.progress.toFixed(0)}%`,
                  }}
                >
                  {projectDetails?.progress.toFixed(0)}%
                </p>
                <ProgressBar now={projectDetails?.progress.toFixed(0)} />
              </div>
              <div class="progress-content">
                <p>{formatDate(projectDetails?.startDate || new Date())}</p>
                <p>{formatDate(projectDetails?.endDate || new Date())}</p>
              </div>
            </div>
            {/* red content */}
            {getStatusLabel() == "Delayed" && (
              <div className="barcode-container-red">
                <div className="barcode-content">
                  <img src={barcode1} className="barcode1-img" />
                  <span>+{days[currentDay]}d</span>
                  <img src={barcode2} className="barcode2-img" />
                </div>
                <p className="text-red">Exp: {adjustDate(projectDetails?.endDate, days[currentDay])}</p>
              </div>
            )}

            {/* blue content */}
            {getStatusLabel() == "Early" && (
              <div className="barcode-container-blue">
                <div className="barcode-content">
                  <img src={barcode3} className="barcode3-img" />
                  <span>{days[currentDay]}d</span>
                  <img src={barcode4} className="barcode4-img" />
                </div>
                <p className="text-blue">Exp: {adjustDate(projectDetails?.endDate, days[currentDay])}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDaysSlider;
