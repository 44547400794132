import React, { useEffect, useState } from "react";
import "../../../assets/css/overview-updated.css";
import Select from "react-select";
import APIServices from "../../../services/APIServices";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { fetchProjectMilestones } from "../../../utils/fetchData";
import { formatDate, selectLoader } from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import { useStore } from "../../../hooks/useStore";
import UpdateDaysSlider from "../../../components/UpdateDaysSlider";

const UpdateProjectForm = ({
  projectId,
  closeModal,
  setUpdatedMilestoneId,
}) => {
  const [store, setStore] = useStore();
  // const [projectId, setProjectId] = useState("");
  const [mileStonesList, setMileStonesList] = useState([]);
  const [mileStones, setMileStones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentMileStone, setCurrentMileStone] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("ontime");
  const [errors, setErrors] = useState({});
  const [updatedDay, setUpdatedDay] = useState(0);
  const [projectData, setProjectData] = useState(null);
  const [projectProgress, setProgress] = useState(0);

  // Single state object to hold all form fields
  const [formData, setFormData] = useState({
    projectId: "",
    status: "",
    daysLate: 0,
    daysEarly: 0,
    updateNote: "",
    notifyClient: false,
  });

  useEffect(() => {
    if (projectData) {
      let latestUpdates = projectData?.latestUpdate[0];
      setFormData((prevData) => ({
        ...prevData,
        status: latestUpdates?.updateStatus?._id || formData.status,
      }));
      setProgress(projectData.progress.toFixed(0));
      if (latestUpdates?.daysEarly > 0) {
        setUpdatedDay(-latestUpdates?.daysEarly);
      } else {
        setUpdatedDay(latestUpdates?.daysLate);
      }
      if (latestUpdates?.updateStatus?.value)
        setCurrentStatus(latestUpdates?.updateStatus?.value);
    }
  }, [projectData]);

  const fetchMilestones = async () => {
    setLoading(true);
    try {
      let milestones = await fetchProjectMilestones(projectId, "update");
      // if (milestones) {
      setMileStones(milestones);
      fetchStatusOptions();
      // }
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setLoading(false);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await APIServices.get(`/enum?item_type=projectupdate`);
      if (response.length > 0) {
        setStatusOptions(response);
        let onTimeStatus = response.find((x) => x.value == "ontime");
        setFormData((prevData) => ({
          ...prevData,
          status: onTimeStatus?._id,
        }));
        currentStatus(onTimeStatus.value);
      }
    } catch (error) {
      console.error("Error fetching status options:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectData();
      fetchStatusOptions();
    }
  }, [projectId]);

  // Fetching project data from the backend
  const fetchProjectData = async () => {
    try {
      // Using axios or fetch to get data from your API
      const response = await APIServices.get(`/project/${projectId}`); // API call with project ID
      console.log(response);
      if (response.projectDetail) {
        setProjectData(response.projectDetail); // Set the response data in state
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    } finally {
      setLoading(false); // Stop loading even if there is an error
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSelectChange = (selected, name) => {
    setFormData((prevData) => ({
      ...prevData,
      milestoneId: selected ? selected.value : "",
    }));

    const currentMilestone = mileStones.find((x) => x._id === selected?.value);
    setCurrentMileStone(currentMilestone);

    if (errors.milestoneId) {
      setErrors((prevErrors) => ({ ...prevErrors, milestoneId: "" }));
    }
  };

  const handleRangeChange = (e) => {
    if (currentMileStone && formData.fromPercentage) {
      if (e[1] > formData.fromPercentage) {
        setFormData((prevData) => ({
          ...prevData,
          toPercentage: e[1],
        }));
      } else {
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        toPercentage: e[1],
      }));
    }
  };

  const handleRadioChange = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      status: option._id,
      // daysEarly: 0,
      daysLate: 0,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      status: "",
      numberofDays: "",
    }));
    setCurrentStatus(option.value);
  };

  const getStatusId = () => {
    if (updatedDay > 0) {
      return statusOptions.find((x) => x.value == "late")._id;
    } else if (updatedDay < 0) {
      return statusOptions.find((x) => x.value == "early")._id;
    } else {
      return statusOptions.find((x) => x.value == "ontime")._id;
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // if (!formData.milestoneId) newErrors.milestoneId = "Milestone is required.";
    // if (!formData.status) newErrors.status = "Status is required.";
    // if (currentStatus == "late" && !formData.daysLate)
    //   newErrors.numberofDays = "Please Enter Number of days.";
    // if (currentStatus == "early" && !formData.daysEarly)
    //   newErrors.numberofDays = "Please Enter Number of days early.";
    if (!formData.updateNote.trim())
      newErrors.updateNote = "Update note cannot be empty.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setSubmitLoading(true);
      const payload = {
        projectId: projectId,
        // milestoneId: formData.milestoneId,
        updateStatus: getStatusId() || "",
        daysLate: updatedDay > 0 ? updatedDay : 0,
        daysEarly: updatedDay < 0 ? Math.abs(updatedDay) : 0,
        // fromPercentage: formData.fromPercentage,
        // toPercentage: formData.toPercentage,
        updateNote: formData.updateNote,
        notifyClient: formData.notifyClient,
      };
      try {
        const response = await APIServices.post("/project-updates", payload);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          if (
            setUpdatedMilestoneId &&
            response?.updatedMilestone?.signOffRequired &&
            response?.updatedMilestone?.percentage == 100
          ) {
            console.log(response.updatedMilestone.signOffRequired);
            setUpdatedMilestoneId(response.updatedMilestone._id);
          }
          closeModal();
        }
        closeModal();
      } catch (err) {
        console.error("Error while verifying email", err);
      } finally {
        setSubmitLoading(false);
      }
    } else {
      console.log("Form has validation errors.");
    }
  };

  if (loading) {
    return <div className="nodata-loader">{selectLoader(70)}</div>;
  }

  return (
    <div className="updatemodal">
      {/* <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <h3 className="project-status-title">Milestone</h3>
            <Select
              className={`form-control select2 ${
                errors.milestoneId ? "form-invalid" : ""
              }`}
              options={mileStonesList}
              placeholder="Select Milestone"
              isClearable
              onChange={handleSelectChange}
              isLoading={loading}
            />
            {errors.milestoneId && (
              <p className="error-message ml-2">{errors.milestoneId}</p>
            )}
          </div>
        </div>
      </div> */}

      {/* {currentMileStone?.dueDate && (
        <p className="due-date">
          Due Date, {formatDate(currentMileStone?.dueDate || new Date())}
        </p>
      )} */}

      {/* <div className="mt-3">
        <RangeSlider
          onInput={handleRangeChange}
          className="range-slider"
          value={[0, formData.toPercentage]}
        />
      </div> */}
      {/* {errors.toPercentage && (
        <p className="error-text">{errors.toPercentage}</p>
      )} */}

      {/* <h3 className="process-parsentage">{formData.toPercentage}%</h3> */}
      <UpdateDaysSlider
        progress={projectProgress}
        projectDetails={projectData}
        updatedDay={updatedDay}
        setUpdatedDay={setUpdatedDay}
      />

      {/* <div className="row mt-3">
        <div className="col-lg-12">
          <h3 className="project-status-title">Project</h3>

          <div class="project-status">
            <label id="status-label" class="status delayed">
              Delayed
            </label>

            <div class="slider-container">
              <button id="decrease-btn" class="slider-btn">
                −
              </button>

              <div class="slider-numbers">
                <span class="number" data-value="0">
                  0
                </span>

                <span class="number" data-value="1">
                  1
                </span>

                <span class="number active" data-value="2">
                  2
                </span>

                <span class="number" data-value="3">
                  3
                </span>

                <span class="number" data-value="4">
                  4
                </span>
              </div>

              <button id="increase-btn" class="slider-btn">
                +
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="row mt-3">
        <div className="col-lg-12">
          <div className="projectProgress-bg">
            <div className="project-progress">
              <div className="progress-bar-container">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={progress}
                  className="slider"
                  onChange={handleInputbarChange}
                  style={{
                    background: `linear-gradient(to right, #14904A ${progress}%, #14904A  ${progress}%)`,

                    "--progress": `${progress}%`,
                  }}
                />

                <div
                  className="thumb"
                  style={{ left: `calc(${progress}% - 25px)` }}
                >
                  {progress}%
                </div>

                <div className="progress-content">
                  <p>April 3</p>

                  <p>May 18</p>
                </div>
              </div>

              <div className="expire-container-red">
                <div className="expire-progress">
                  <span>+2d</span>

                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#FF0000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <p>Exp: May 20</p>
              </div>

              <div className="expire-container-blue">

           <div className="expire-progress">

            <span>-2d</span>

            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">

               <path d="M1 13L7 7L1 1" stroke="#218BC8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>

             </svg>
           </div>

           <p>Exp: May 20</p>

        </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              style={{ minHeight: "120px" }}
              className={`form-control ${
                errors.updateNote ? "form-invalid" : ""
              }`}
              name="updateNote"
              value={formData.updateNote}
              onChange={handleInputChange}
              placeholder="Write update note"
            ></textarea>
            {errors.updateNote && (
              <p className="error-message ml-2">{errors.updateNote}</p>
            )}
          </div>
        </div>
      </div>

      <div className="switch-cover">
        <h4>Notify Client</h4>
        <div className="form-switch">
          <input
            type="checkbox"
            checked={formData.notifyClient}
            onChange={handleInputChange}
            name="notifyClient"
            hidden="hidden"
            id="projectdelay"
          />
          <label className="switch" htmlFor="projectdelay"></label>
        </div>
      </div>
      <button className="btn-black" onClick={handleSubmit}>
        {!submitLoading ? "Update" : selectLoader(35)}
      </button>
    </div>
  );
};

export default UpdateProjectForm;
