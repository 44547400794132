import APIServices from "../services/APIServices";

export const fetchRolesList = async (
  setRoles,
  setLoading,
  setError,
  isOrgUser = false
) => {
  setLoading(true);
  try {
    const response = await APIServices.get(
      `/user-roles?type=${isOrgUser ? 2 : 1}`
    ); // Adjust the URL as necessary
    if (response.userRoles) {
      const mappedRoles = response.userRoles.map((role) => ({
        value: role.role, // Use 'role' as the value
        label: role.name,
        id: role._id, // Use 'name' as the label
      }));
      setRoles(mappedRoles);
    }
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

export const fetchPermissions = async (setPermissions) => {
  try {
    const response = await APIServices.get("/permissions"); // Adjust the URL as necessary

    if (response.length > 0) {
      const permissionsData = response;

      const formattedPermissions = permissionsData.map((permission) => ({
        value: permission._id, // or permission._id if needed
        label: permission.name,
      }));

      setPermissions(formattedPermissions);
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
  }
};

export const fetchTeamMembers = async (
  setTeams,
  setCurrentUser,
  setLoading,
  user,
  includeme = false
) => {
  let membersOptions = [];
  let currentUser = {};
  try {
    const response = await APIServices.get(
      `/team/${user?.companyId?._id}?status=accepted&includeme=${includeme}`
    );
    if (response.members) {
      currentUser = response.members.find((x) => x._id == user._id);
      setCurrentUser(currentUser);
      membersOptions = response.members.map((member) => ({
        value: member._id, // or permission._id if needed
        label: member.fullName,
        role: member.userType._id,
      }));
      // membersOptions = membersOptions.filter(x=>x.value != user._id)
      setTeams(membersOptions || []);
    } else if (response?.data?.message) {
      setTeams([]);
    }
  } catch (error) {
    console.error("Error fetching the teams:", error);
  } finally {
    setLoading(false);
  }
};

export const fetchEnumOptions = async (enumName) => {
  try {
    const response = await APIServices.get(`/enum?item_type=${enumName}`); // Adjust the URL as necessary

    if (response.length > 0) {
      // const optionsData = response;

      // const formattedPermissions = optionsData.map((option) => ({
      //   value: option._id,
      //   label: option.name,
      // }));

      // setData(formattedPermissions);
      return response;
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
  }
};

// this api will be triggeres while creating new project/template in milstone screen
export const fetchProjectTeamMembers = async (
  projectId,
  setData,
  type = ""
) => {
  try {
    const response = await APIServices.get(
      `/project-team/${projectId}?type=${type}`
    );

    if (response.teamMembers.length > 0) {
      const optionsData = response.teamMembers;
      // console.log(optionsData);

      const formattedPermissions = optionsData.map((option) => ({
        value: option.userId?._id,
        label: option.userId?.fullName,
      }));
      // console.log(formattedPermissions);

      setData(formattedPermissions);
      return formattedPermissions;
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
  }
};

// this api will be triggeres while creating new project/template in milstone screen
export const fetchProjectTeamMemberswithNone = async (
  projectId,
  setData,
  type = ""
) => {
  try {
    let updatedTeam = [];
    const projectTeam = await fetchProjectTeamMembers(projectId, setData, type);

    updatedTeam = [
      {
        value: null,
        label: "None",
      },
      ...projectTeam,
    ];
    console.log("updatedTeam", updatedTeam);
    
    setData(updatedTeam);
  } catch (error) {
    console.error("Error fetching permissions:", error);
  }
};

// this api will be used to get the list of milestones of a project
export const fetchProjectMilestones = async (projectId, tyepeOf = "") => {
  try {
    const response = await APIServices.get(
      `/project-milestone?projectId=${projectId}&type=${tyepeOf}`
    );
    if (response.length > 0) {
      return response;
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
  }
};
