import React, { useEffect, useState } from "react";
import "../../../assets/css/overview-addnew-milestones.css";
import "../../../assets/css/overview-select-template.css";
import AddTemplate from "./AddTemplate";
import SelectTemplate from "./SelectTemplate";
import { useAuth } from "../../../contexts/AuthContext";

const MileStone = ({projectDetails, milestoneProgress , setMilestoneProgress , setProjectStatus}) => {
  // State to track which template to render
  const { user } = useAuth();
  const [templateId, setTemplateId] = useState("");
  const [companyId, seCompanyId] = useState("");
  useEffect(() => {
    if (user) {
      seCompanyId(user?.companyId?._id);
    }
  }, [user]);

  useEffect(() => {
    if (milestoneProgress == null) {
      setTemplateId("")
    }
  }, [milestoneProgress]);
  
  return (
    <>
      {!milestoneProgress && (
        <div className="milestone pt-lg-5 mb-3">
          <div className="row justify-content-center mt-100">
            <div className="col-md-6 mb-3">
              <button
                type="button"
                onClick={() => setMilestoneProgress("add")}
                className="bg-white btn-custom btn-lg btn-block w-100"
              >
                + Add New
              </button>
            </div>
            <div className="col-md-6 mb-3">
              <button
                type="button"
                onClick={() => setMilestoneProgress("select")}
                className="bg-white btn-custom btn-lg btn-block w-100"
              >
                + Select Template
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Conditionally render components based on milestoneProgress */}
      {milestoneProgress === "add" && (
        <AddTemplate  projectDetails={projectDetails} companyId={companyId} setMilestoneProgress={setMilestoneProgress} selectedTemplateId={templateId} setSelectedTemplateId={setTemplateId}/>
      )}
      {milestoneProgress === "select" && <SelectTemplate setTemplateId={setTemplateId}  setMilestoneProgress={setMilestoneProgress} companyId={companyId}/>}
    </>
  );
};

export default MileStone;
