import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
// import signature from "../assets/img/signature.png";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import { selectLoader } from "../../../utils/Helpers";

const SignatureModal = ({
  requestId = "",
  isQouteApproval = "",
  optionId = "",
  setMilestoneId,
  closeModal,
  openFeedback,
}) => {
  const sigCanvasRef = useRef(null); // Reference to the signature canvas
  const [imageURL, setImageURL] = useState(""); // State to hold the signature as an image
  const [loading, setLoading] = useState(false);
  // Function to clear the signature
  const clearSignature = () => {
    sigCanvasRef.current.clear();
    setImageURL(""); // Clear the saved image URL
  };
  // console.log(requestId);
  // Function to handle API submission
  const handleSubmit = async () => {
    if (sigCanvasRef.current.isEmpty()) {
      appToast(
        "Please provide a signature before submitting.",
        TOAST_TYPE.ERROR
      );
      return;
    }

    setLoading(true);

    try {
      // Convert the base64 signature image to a Blob
      const base64Image = sigCanvasRef.current.toDataURL("image/png");
      const blob = await fetch(base64Image).then((res) => res.blob());

      // Create a FormData object
      const formData = new FormData();
      formData.append("approverSign", blob, "signature.png"); // Append the signature as a file
      if (isQouteApproval) {
        formData.append("optionId", optionId); // Selected Option Id.
      }
      // Make the API call
      const response = isQouteApproval
        ? await APIServices.post(
            `/project-request-quote/approve/${requestId}`,
            formData,
            {
              "Content-Type": "multipart/form-data",
            }
          )
        : await APIServices.put(
            `/project-requests/approve/${requestId}`,
            formData,
            {
              "Content-Type": "multipart/form-data",
            }
          );
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        setMilestoneId(response?.milestone?._id);
        console.log(response?.milestone?._id);

        // closeModal();
        openFeedback();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      // Handle errors
      appToast(error, TOAST_TYPE.ERROR);
    } finally {
      setLoading(false); // Reset the submitting state
    }
  };

  return (
    <>
      <div className="signature-body-bg">
        {" "}
        <div className="signature-body">
          <p onClick={clearSignature} style={{ cursor: "pointer" }}>
            Clear
          </p>

          {/* Signature Canvas */}
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{
              className: "signature-canvas",
              style: { width: "100%", height: "150px" }, // Adapts to existing design
            }}
          />
          {/* 
if user wants a preview of sign */}
          {/* <img src={imageURL || signature} alt="comp1" className="signature-img" /> */}

          <hr />
        </div>
      </div>
      <button className="btn-black" onClick={handleSubmit} type="submit">
        {loading ? selectLoader(35) : "Add"}
      </button>
    </>
  );
};

export default SignatureModal;
