import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import addIcon from "../../../assets/img/add.svg";
import deleteBtn from "../../../assets/img/delete.svg";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import { selectLoader } from "../../../utils/Helpers";

const AddQuoteModal = ({
  projectId,
  changeReqId,
  qouteId = "",
  closeModal,
}) => {
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [qouteDetails, setQouteDetails] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [formData, setFormData] = useState({
    expireInDays: 30,
    projectId: projectId,
    projectRequestId: changeReqId,
    options: [
      {
        _id: 1,
        title: "Option 1",
        description: "",
        additionalCost: "",
        numberOfDays: "",
        lineItems: [],
      },
    ],
    newOptionId: 2,
  });
  const [activeOptionId, setActiveOptionId] = useState(1);
  const [validationErrors, setValidationErrors] = useState({});

  const activeOption = formData.options.find(
    (option) => option._id === activeOptionId
  );

  useEffect(() => {
    if (qouteId) {
      setEditing(true);
      fetchQouteDetails();
      console.log(qouteId);
    }
  }, [qouteId]);

  useEffect(() => {
    if (qouteDetails) {
      console.log(qouteDetails);
      setFormData((prevData) => ({
        ...prevData,
        expireInDays: qouteDetails.expireInDays,
        options: qouteDetails.options,
      }));
      setActiveOptionId(qouteDetails.options[0]._id);
    }
  }, [qouteDetails]);

  const fetchQouteDetails = async () => {
    setPageLoading(true);
    try {
      let response = await APIServices.get(`/project-request-quote/${qouteId}`);
      if (response.data) {
        console.log(response.data);
        setQouteDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
    } finally {
      setPageLoading(false);
    }
  };

  // Handlers
  const handleInputChange = (key, value) => {
    console.log(key);

    if (key == "expireInDays") {
      setFormData((prevData) => ({
        ...prevData,
        expireInDays: value,
      }));
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        expireInDays: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        options: prevData.options.map((option) =>
          option._id === activeOptionId ? { ...option, [key]: value } : option
        ),
      }));
      if (validationErrors?.[activeOptionId]?.[key])
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [activeOptionId]: { ...prevErrors[activeOptionId], [key]: "" },
        })); // Clear error for the active option
    }
  };

  const handleLineItemChange = (id, text) => {
    setFormData((prevData) => ({
      ...prevData,
      options: prevData.options.map((option) =>
        option._id === activeOptionId
          ? {
              ...option,
              lineItems: option.lineItems.map((item) =>
                item.id === id ? { ...item, text } : item
              ),
            }
          : option
      ),
    }));
    if (validationErrors?.[activeOptionId]?.lineItems)
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [activeOptionId]: { ...prevErrors[activeOptionId], lineItems: "" },
      }));

    let currentLinecount = formData.options.find(
      (option) => option._id === activeOptionId
    ).lineItems.length;

    // Ensure the new input is focused after the state update.
    setTimeout(() => {
      if (inputRefs.current && inputRefs.current.length > 0)
        inputRefs.current.length = currentLinecount - 1;
    }, 0);
  };

  const handleAddLineItem = () => {
    setFormData((prevData) => {
      const newLineItems = prevData.options.map((option) =>
        option._id === activeOptionId
          ? {
              ...option,
              lineItems: [
                ...option.lineItems,
                { id: option.lineItems.length + 1, text: "" },
              ],
            }
          : option
      );

      return {
        ...prevData,
        options: newLineItems,
      };
    });

    // Ensure the new input is focused after the state update.
    setTimeout(() => {
      // console.log(inputRefs.current.length);

      if (inputRefs.current && inputRefs.current.length > 0) {
        inputRefs?.current[inputRefs.current.length - 1]?.focus();
      }
    }, 0);
  };

  const handleRemoveLineItem = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      options: prevData.options.map((option) =>
        option._id === activeOptionId
          ? {
              ...option,
              lineItems: option.lineItems.filter((item) => item.id !== id),
            }
          : option
      ),
    }));

    // Ensure the new input is focused after the state update.
    setTimeout(() => {
      if (inputRefs.current && inputRefs.current.length > 0)
        inputRefs.current.length = inputRefs.current.length - 1;
    }, 0);
  };

  const handleAddOption = () => {
    if (validateFields()) {
      setFormData((prevData) => ({
        ...prevData,
        options: [
          ...prevData.options,
          {
            _id: prevData.newOptionId,
            title: `Option ${prevData.newOptionId}`,
            description: "",
            additionalCost: "",
            numberOfDays: "",
            lineItems: [],
          },
        ],
        newOptionId: prevData.newOptionId + 1,
      }));
      setActiveOptionId(formData.newOptionId); // Switch to the new option
      setValidationErrors({}); // Clear validation errors
      let currenOption = formData.options.find(
        (option) => option._id == activeOptionId
      );
      console.log(currenOption.lineItems.length);
      // Ensure the new input is focused after the state update.
      setTimeout(() => {
        if (inputRefs.current && inputRefs.current.length > 0)
          inputRefs.current.length = 0;
      }, 0);
    }
  };

  const handleRemoveOption = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      options: prevData.options.filter((option) => option._id !== id),
    }));
    setActiveOptionId(formData.options[0]?._id || null); // Switch to the first option or null
  };

  const validateFields = () => {
    const errors = {};
    if (!formData.expireInDays)
      errors.expireInDays = "Please enter days of expiry.";
    formData.options.forEach((option) => {
      const optionErrors = {};
      if (!option.title) optionErrors.title = "Please enter title .";
      if (!option.description)
        optionErrors.description = "Please enter description.";
      if (!option.additionalCost)
        optionErrors.additionalCost = "Please enter additional cost.";
      if (option.numberOfDays === "")
        optionErrors.numberOfDays = "Please enter Number of days.";
      if (option.lineItems.some((item) => !item.text))
        optionErrors.lineItems = "All line items must be filled.";

      if (Object.keys(optionErrors).length > 0) {
        errors[option._id] = optionErrors;
      }
    });

    setValidationErrors(errors);
    console.log(errors);

    // Return true if no errors exist
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      setLoading(true);
      // Exclude newOptionId from payload
      const { newOptionId, ...payload } = formData;
      const { projectId, projectRequestId, ...editPayload } = payload;
      if (isEditing) {
        editPayload.options.forEach((item) => {
          if (!isNaN(item._id)) {
            delete item._id; // Removes _id if it's a number
          }
        });
      }
      try {
        const response = isEditing
          ? await APIServices.put(
              `/project-request-quote/${qouteId}`,
              editPayload
            )
          : await APIServices.post("/project-request-quote", payload);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  if (pageLoading) {
    return <div className="nodata-loader"> {selectLoader(60)}</div>;
  }

  return (
    <>
      {/* Expires Dropdown */}
      <div className="row mt-3">
        <div className="col-lg-12">
          <div
            className={`expires-form-group ${
              validationErrors?.expireInDays ? "form-invalid" : ""
            }`}
          >
            <label className="label-title d-flex align-items-center mb-0 me-3">
              Expires
            </label>
            <div className="select-option-box ">
              <input
                type="text"
                className={`select-option `}
                placeholder="Days"
                value={formData?.expireInDays || ""}
                // value={
                //   formData?.expireInDays && `${formData?.expireInDays} Days`
                // }
                onChange={(e) => {
                  const validValue = e.target.value.replace(/[^0-9.]/g, "");
                  if (validValue < 365) {
                    handleInputChange("expireInDays", validValue);
                  } else {
                    appToast(
                      "Values of days should not more than 365 days",
                      TOAST_TYPE.WARNING
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Options List */}
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="form-group">
            <div className="new-addition-list">
              {formData.options.map((option, index) => (
                <a
                  key={option._id}
                  className={`${
                    option._id === activeOptionId ? "active" : ""
                  } `}
                  onClick={() => setActiveOptionId(option._id)}
                >
                  <p>Option {index + 1}</p>
                  <span
                    className={`${
                      validationErrors[option._id] ? "option-invalid" : ""
                    }`}
                  >
                    {index + 1}
                  </span>
                </a>
              ))}
              <a onClick={handleAddOption}>
                <p>&nbsp;</p>
                <img src={addIcon} className="add-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Request Title */}
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                validationErrors[activeOptionId]?.title ? "form-invalid" : ""
              }`}
              placeholder="Title"
              value={activeOption?.title || ""}
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
            {validationErrors[activeOptionId]?.title && (
              <small className="text-danger">
                {validationErrors[activeOptionId].title}
              </small>
            )}
          </div>
        </div>
      </div>

      {/* Request Description */}
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Description"
              className={`form-control ${
                validationErrors[activeOptionId]?.description
                  ? "form-invalid"
                  : ""
              }`}
              value={activeOption?.description || ""}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
            {validationErrors[activeOptionId]?.description && (
              <small className="text-danger">
                {validationErrors[activeOptionId].description}
              </small>
            )}
          </div>
        </div>
      </div>

      {/* Line Items */}
      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group-items">
            <div className="attech-file-cover">
              <label className="attech-btn">Line items</label>
              <label
                className="add-btn-text cursor_pointer"
                onClick={handleAddLineItem}
              >
                + Add
              </label>
            </div>
            {activeOption?.lineItems.map((item, index) => (
              <div className="user-box-cover" key={item.id}>
                <div className="username">
                  <input
                    type="text"
                    className="line-item-form"
                    placeholder="Enter details"
                    value={item.text}
                    ref={(el) => (inputRefs.current[index] = el)} // Assign ref to each input
                    onChange={(e) =>
                      handleLineItemChange(item.id, e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent default Enter key behavior
                        handleAddLineItem();
                      }
                    }}
                  />
                </div>
                <img
                  src={deleteBtn}
                  alt="comp1"
                  className="deleteBtn"
                  onClick={() => handleRemoveLineItem(item.id)}
                />
              </div>
            ))}
            {validationErrors[activeOptionId]?.lineItems && (
              <small className="text-danger">
                {validationErrors[activeOptionId].lineItems}
              </small>
            )}
          </div>
        </div>
      </div>

      {/* Additional Cost */}
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                validationErrors[activeOptionId]?.additionalCost
                  ? "form-invalid"
                  : ""
              }`}
              placeholder="Additional Cost $"
              //   value={activeOption?.additionalCost || ""}
              value={
                activeOption?.additionalCost &&
                `$ ${activeOption?.additionalCost}`
              }
              onChange={(e) => {
                // Only allow digits and period for decimal values
                const validValue = e.target.value.replace(/[^0-9.]/g, "");
                console.log(validValue);
                if (validValue.length <= 7) {
                  handleInputChange("additionalCost", validValue);
                } else {
                  appToast(
                    "The Additional Cost value must not exceed 7 digits.",
                    TOAST_TYPE.WARNING
                  );
                }
              }}
            />
            {validationErrors[activeOptionId]?.additionalCost && (
              <small className="text-danger">
                {validationErrors[activeOptionId].additionalCost}
              </small>
            )}
          </div>
        </div>
      </div>

      {/* Number of Days */}
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                validationErrors[activeOptionId]?.numberOfDays
                  ? "form-invalid"
                  : ""
              }`}
              placeholder="Number of Days"
              value={activeOption?.numberOfDays}
              onChange={(e) => {
                const validValue = e.target.value.replace(/[^0-9.]/g, "");

                // Allow empty value for backspace
                if (validValue === "") {
                  handleInputChange("numberOfDays", "");
                  return;
                }

                if (validValue <= 100) {
                  handleInputChange("numberOfDays", Number(validValue));
                } else {
                  appToast(
                    "The Number of Days value must not exceed 100.",
                    TOAST_TYPE.WARNING
                  );
                }
              }}
            />
            {validationErrors[activeOptionId]?.numberOfDays && (
              <small className="text-danger">
                {validationErrors[activeOptionId].numberOfDays}
              </small>
            )}
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="row">
        <div className="col-lg-12">
          <button className="btn-black" onClick={handleSubmit}>
            {loading ? selectLoader(35) : isEditing ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddQuoteModal;
