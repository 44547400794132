import React, { useEffect, useState } from "react";
import starSvg from "../../../assets/img/star.svg";
import starOpSvg from "../../../assets/img/starOpacity.svg";
import SidebarModal from "../../../components/SidebarModal";
import ReqFeedbackModal from "../updatedOverviewModals/ReqFeedbackModal";
import { useAuth } from "../../../contexts/AuthContext";
import axios from "axios"; // Assuming axios is used for API requests
import APIServices from "../../../services/APIServices";
import AddFeedbackModal from "../updatedOverviewModals/AddFeedbackModal";
import {
  formatDateTime,
  hasPermission,
  selectLoader,
} from "../../../utils/Helpers";

const FeedbackTab = ({ selectedReqId, projectId, currentTab}) => {
  const { user, isUser1, userRole } = useAuth();
  const [loading, setLoading] = useState(false); // State for feedback
  const [feedbackData, setFeedbackData] = useState([]); // State for feedback
  const [requestId, setRequestId] = useState("");
  const [activeTab, setActiveTab] = useState("requested");

  const [modals, setModals] = useState({
    requestFeedbackModal: false,
    AddFeedbackModal: false,
  });

  useEffect(() => {
    if (selectedReqId && currentTab == 4) {
      fetchSingleFeedback();
      console.log(selectedReqId);
    }else{
      fetchFeedback("requested");
    }
  }, [selectedReqId]);

  useEffect(() => {
    if(!selectedReqId){
      if (activeTab == "requested") {
        fetchFeedback("requested");
      } else if (activeTab == "received") {
        fetchFeedback("given");
      }
    }
  }, [activeTab]);

  const fetchFeedback = async (type = "requested") => {
    try {
      setLoading(true);
      const response = await APIServices.get(
        `/project-milestone-feedback/${projectId}?feedbackType=${type}`
      ); // Update with your API endpoint
      if (response.data) {
        setFeedbackData(response.data);
      }
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSingleFeedback = async () => {
    try {
      setLoading(true);
      const response = await APIServices.get(
        `/project-milestone-feedback/detail/${selectedReqId}`
      ); // Update with your API endpoint
      if (response.data) {
        setFeedbackData(response.data);
      }
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectId && currentTab == 4 && !selectedReqId) {
      fetchFeedback();
    }
  }, [projectId, currentTab]);

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen,
    }));
  };

  const updateandCloseModal = (modalType) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: false,
    }));
    if (selectedReqId) {
      fetchSingleFeedback();
    } else {
      if (modalType == "requestFeedbackModal") {
        fetchFeedback("requested");
      } else if (modalType == "AddFeedbackModal") {
        setActiveTab("received");
      }
    }
  };

  const handleAddFeedback = (id) => {
    setRequestId(id);
    toggleModal("AddFeedbackModal", true);
  };

  return (
    <div className="card-body-content request-content">

      {!selectedReqId && <div className="bg-white btn-tab-group">
        <button
          className={`btn btn-custom ${
            activeTab === "requested" ? "btn-dark" : "btn-light"
          }`}
          onClick={() => setActiveTab("requested")}
        >
          {isUser1 ? "Requested" : "Pending"}
        </button>
        <button
          className={`btn btn-custom  ${
            activeTab === "received" ? "btn-dark" : "btn-light"
          }`}
          onClick={() => setActiveTab("received")}
        >
          {isUser1 ? "Received" : "Given"}
        </button>
      </div>}
      {loading ? (
        <div className="">{selectLoader(70)}</div>
      ) : (
        <>
          <ul className="chat-btn-list feedback-action">
            {hasPermission(userRole, "feedback_request") &&
              activeTab == "requested" && (
                <li>
                  <a
                    className="cursor_pointer"
                    onClick={() => toggleModal("requestFeedbackModal", true)}
                  >
                    + Ask for Feedback
                  </a>
                </li>
              )}
          </ul>

          {feedbackData?.length > 0 ? (
            feedbackData?.map((feedback) => (
              <div className="feedback_cover mb-2 " key={feedback._id}>
                <div className="feedback_card">
                  <div className="feedback_card_content">
                    <label>
                      <b className="darkblue-text">
                        {feedback.requestType == "requested" &&
                          "Feedback Request:"}{" "}
                        {feedback.milestoneId?.name || "N/A"}
                      </b>
                    </label>
                    <div className="feedback_pera">
                      <p>
                        {feedback.description || "No description provided."}
                      </p>
                    </div>
                    <div className="star_review request_footer_inner">
                      <ul>
                        {[...Array(5)].map((_, index) => (
                          <li key={index}>
                            <img
                              src={
                                index < (feedback.rating || 0)
                                  ? starSvg
                                  : starOpSvg
                              }
                              alt="Star"
                            />
                          </li>
                        ))}
                      </ul>

                      <div className={`chat-time you`}>
                        {feedback?.createdBy?.fullName}{" "}
                        {/* {activity.createdBy.email.split("@")[0]} -{" "} */}
                        {formatDateTime(feedback.createdDate)}
                      </div>
                    </div>
                    {hasPermission(userRole, "feedback_give") &&
                      feedback.requestType != "given" && (
                        <div className="request_footer_inner">
                          <button
                            className="edit-btn"
                            style={{
                              width: "fitContent",
                              minWidth: "10px",
                            }}
                            onClick={() => handleAddFeedback(feedback._id)}
                          >
                            + Add Feedback
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No feedback available.</p>
          )}
        </>
      )}
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.AddFeedbackModal}
        onHide={() => toggleModal("AddFeedbackModal", false)}
        headerTitle={"Feedback"}
      >
        <AddFeedbackModal
          projectId={projectId}
          requestId={requestId}
          closeModal={() => {
            updateandCloseModal("AddFeedbackModal");
          }}
        />
      </SidebarModal>

      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.requestFeedbackModal}
        onHide={() => toggleModal("requestFeedbackModal", false)}
        headerTitle={"Request Feedback"}
      >
        <ReqFeedbackModal
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal("requestFeedbackModal");
          }}
        />
      </SidebarModal>
    </div>
  );
};

export default FeedbackTab;
