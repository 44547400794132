export const ProjectListStrings = {
  fullNamePlaceholder: "Full Name",
  emailPlaceholder: "Email",
  companyNamePlaceholder: "Company Name",
  prevButton: "Prev",
  closeButton: "Close",
  lastButton: "Done",
  nextButton: "Next",
  skipButton: "Skip",
  searchPlaceholder: "Search",
  projectManagerOption: "Project Manager",
  statusOption: "Status",
  recentOption: "Recent",
  inactiveOption: "Inactive",
  homeTabAlt: "List view",
  profileTabAlt: "Grid view",
  serviceMVPWebsite: "ServiceMVP website",
  onTimeTag: "#on-time",
  updatesCount: "Updates",
  updateButton: "Update",
  signOffRequest: "Sign-off Request",
  designProgress: "Design 76%",
  nextMilestoneText: "Next Milestone: Development",
  milestoneDate1: "April 3",
  milestoneDate2: "May 18",
  modifyText: "Modify by Robert, Today",
  noProjectsTitle: "You have no projects at the moment.",
  noProjectsDescription:
    "To get started, click on the + button below to add a new project and begin organizing your tasks and ideas.",
  createProjectText: "Create a project",
  createTeamText: "Add Team",
  createTaskText: "Create a Work template",
  addTeam: "Add Team",
  addSomethingTooltip: "Add something!",
  editProfileHeader: "Edit Profile",

  // New Strings for the Edit Profile Modal
  uploadIconAlt: "Upload Icon",
  userImageAlt: "User",
  phoneNumberVerifyText: "Verify",
  saveButtonText: "Save",

  // Strings for Main Sidebar Modal
  templatesLibraryText: "Template Library",
  supportText: "Support",
  whatsNewText: "What's New?",
  resourcesText: "Resources",
  clientDirectoryText: "Client Directory",
  myTeamText: "My Team",
  company: "Company",
  settingsText: "Settings",
  logoutText: "Logout",
  editProfileText: "Edit Profile",
  verifyText: "Verify",
  arrowIconAlt: "arrow icon",
};
