import React, { useEffect, useState } from "react";
import RequestListTab from "./requestscreens/RequestListTab";
import RequestDetails from "./requestscreens/RequestDetails";
import { useStore } from "../../../hooks/useStore";

const RequestTab = ({
  currentTab,
  setCurrentTab,
  selectedReqId,
  activeTabView,
  setActiveView,
  setSelectedReqId,
  setUpdateDetails,
}) => {
  const [store] = useStore();
  const [activeTab, setActiveTab] = useState("requestList");
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [parentId, setParentId] = useState("");

  useEffect(() => {
    if (currentTab == 2) {
      if (selectedReqId) {
        setActiveTab("requestDetails");
      } else {
        setActiveTab("requestList");
      }
    }
  }, [currentTab, selectedReqId]);

  useEffect(() => {
    if (activeTabView == "requestList") {
      setActiveTab("requestList");
      setActiveView("");
    }
  }, [activeTabView]);

  const handleRequestClick = (requestId, parentRequestId) => {
    setSelectedRequestId(requestId); // Store the clicked request ID
    setParentId(parentRequestId);
    setActiveTab("requestDetails"); // Switch to the details tab
  };

  const handleRequestCompleted = () => {
    setActiveTab("requestList");
    setUpdateDetails();
  };

  // useEffect(() => {
  //   if (currentTab == 2 && !selectedReqId) setActiveTab("requestList");
  // }, [currentTab]);

  return (
    <div>
      {activeTab === "requestList" && (
        <RequestListTab
          currentTab={currentTab}
          setParentTab={setActiveTab}
          onRequestClick={handleRequestClick} // Pass handler to the child
        />
      )}
      {activeTab === "requestDetails" && (
        <RequestDetails
          currentTab={currentTab}
          selectedReqId={selectedReqId}
          setSelectedReqId={setSelectedReqId}
          requestId={selectedRequestId}
          parentId={parentId}
          setCurrentTab={setCurrentTab}
          setActiveTab={handleRequestCompleted}
          setUpdateDetails={setUpdateDetails}
        />
      )}
    </div>
  );
};

export default RequestTab;
