import React, { useEffect, useState } from "react";
import Select from "react-select"; // Assuming you're using 'react-select'
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import {
  getformattedPhoneNumber,
  getPlainPhoneNumber,
  getUtcOffsetLabel,
  selectLoader,
} from "../../../utils/Helpers";
import moment from "moment-timezone";
import PhoneNumberInput from "../../../components/PhoneInput";

const OrganizationForm = ({ initialData = null, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    website: "",
    city: "",
    state: "",
    timezone: "US/Pacific",
    countryDialCode: "",
    countryCode: "US",
  });
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState([]);

  const fetchOrgDetails = async (id) => {
    setLoading(true);
    try {
      const response = await APIServices.get(`/organizations/${id}`); // Adjust the URL as necessary
      if (response.organization) {
        let orgData = response.organization;
        setFormData({
          name: orgData.name,
          phoneNumber: getformattedPhoneNumber(orgData.phoneNumber),
          website: orgData.website,
          city: orgData.city,
          state: orgData.state,
          timezone: orgData.timezone, // Set as per your requirements if needed
          countryDialCode: orgData.countryDialCode,
          countryCode: orgData.countryCode,
        });
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Set form data if editing
  useEffect(() => {
    if (initialData) {
      fetchOrgDetails(initialData._id);
      setEditing(true);
    }
  }, [initialData]);

  // Get the list of time zones with UTC offset labels
  useEffect(() => {
    const timezones = moment.tz.names().map((timezone) => {
      const offsetLabel = getUtcOffsetLabel(timezone);
      const offset = moment.tz(timezone).utcOffset(); // Get the raw UTC offset in minutes
      return {
        value: timezone,
        label: `${offsetLabel} ${timezone}`,
        offset: offset, // Store the offset for sorting
      };
    });
    // Sort timezones based on the UTC offset, from negative to positive
    timezones.sort((a, b) => a.offset - b.offset);

    setOptions(timezones);
  }, []);

  // Handle input change for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    

    if (
      /^[a-zA-Z\s!@#$%^&*()_+.,;:<>?]*$/.test(value) ||
      value === "" ||
      name == "phoneNumber"
    ) {
      // Update form data
      setFormData({
        ...formData,
        [name]: value,
      });

      // Remove errors dynamically when the user enters valid data
      if (errors[name]) {
        setErrors({
          ...errors,
          [name]: "",
        });
      }
    }
  };

  // Handle Select change
  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption);
    
    setFormData({
      ...formData,
      timezone: selectedOption.value,
    });

    // Remove the timezone error dynamically when a selection is made
    if (errors.timezone) {
      setErrors({
        ...errors,
        timezone: "",
      });
    }
  };

  const handlePhoneChange = (val, countryDialCode, countryCode) => {
    setFormData({
      ...formData,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
      countryCode: countryCode,
    });

    // Clear the error message for the current field being changed
    setErrors({
      ...errors,
      phoneNumber: "",
    });
  };

  // Validate the form before submission
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Organization Name is required";
    }
    if (!getPlainPhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number is required";
    }
    if (getPlainPhoneNumber(formData.phoneNumber).length > 0 && getPlainPhoneNumber(formData.phoneNumber).length < 10) {
      newErrors.phoneNumber = "Not enough digits for a phone number";
    }
    if (!formData.city) {
      newErrors.city = "City is required";
    }
    if (!formData.state) {
      newErrors.state = "State is required";
    }
    if (!formData.timezone) {
      newErrors.timezone = "Time Zone is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = isEditing
      ? `/organizations/${initialData._id}`
      : "/organizations";

    if (validateForm()) {
      setLoading(true);
      let plainNumber = getPlainPhoneNumber(formData.phoneNumber);
      const updatedFormValues = {
        ...formData,
        phoneNumber: plainNumber,
      };
      try {
        const response = isEditing
          ? await APIServices.put(endpoint, updatedFormValues)
          : await APIServices.post(endpoint, updatedFormValues);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
        closeModal();
      }
      if (!isEditing) {
        // Reset form
        setFormData({
          name: "",
          phoneNumber: "",
          website: "",
          city: "",
          state: "",
          timezone: null,
        });
        setErrors({});
      }
    } else {
      console.log("Form validation failed");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors.name ? "form-invalid" : ""}`}
              name="name"
              placeholder="Organization Name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <PhoneNumberInput
              isDisable={false}
              value={formData.phoneNumber}
              onPhoneChange={(val) =>
                handlePhoneChange(val, formData.countryDialCode)
              }
              countryDialCode={formData.countryDialCode}
              countryName={formData.countryCode}
              onCountryChange={(country) => {
                setFormData({
                  ...formData,
                  countryDialCode: country.dialCode,
                  countryCode: country.value,
                });
                handlePhoneChange(
                  formData.phoneNumber,
                  country.dialCode,
                  country.value
                );
              }}
              errors={errors.phoneNumber}
            />
            {errors.phoneNumber && (
              <div className="error-message">{errors.phoneNumber}</div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="website"
              placeholder="Website (optional)"
              value={formData.website}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors.city ? "form-invalid" : ""}`}
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
            {errors.city && <div className="error-message">{errors.city}</div>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors.state ? "form-invalid" : ""}`}
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleChange}
            />
            {errors.state && (
              <div className="error-message">{errors.state}</div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className={`form-control select2 form-control-select ${
                errors.timezone ? "form-invalid" : ""
              }`}
              options={options}
              value={options.find(
                (option) => option.value === formData.timezone
              )}
              onChange={handleSelectChange}
              placeholder="Time Zone"
              styles={{ width: "100%" }}
            />
            {errors.timezone && (
              <div className="error-message">{errors.timezone}</div>
            )}
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-black">
        {!loading ? (isEditing ? "Edit" : "Add") : selectLoader(35)}
      </button>
    </form>
  );
};

export default OrganizationForm;
