import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  fetchProjectMilestones,
  fetchProjectTeamMembers,
} from "../../../utils/fetchData";
import { selectLoader } from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import feedbackReview from "../../../assets/img/starbg.svg";
import plusBtn from "../../../assets/img/plus.svg";
import deleteBtn from "../../../assets/img/delete.svg";

const AddFeedbackModal = ({ projectId, closeModal, requestId }) => {
  const [loading, setLoading] = useState(true);
  const [mileStonesList, setMileStonesList] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [description, setDescription] = useState("");
  const [clients, setClients] = useState([{ id: 1, selectedClient: null }]); // Initial client row
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [rating, setRating] = useState(5); // For star rating

  useEffect(() => {
    if (projectId) {
      fetchMilestones();
    }
  }, [projectId]);

  const fetchMilestones = async () => {
    setLoading(true);
    try {
      let milestones = await fetchProjectMilestones(projectId, "update");
      if (milestones?.length > 0) {
        const formattedPermissions = milestones.map((option) => ({
          value: option?._id,
          label: option?.name,
        }));
        setMileStonesList(formattedPermissions);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleStarClick = (index) => {
    setRating(index + 1); // Stars are 1-indexed
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedMilestone) {
      newErrors.milestone = "Please select a milestone.";
    }
    if (!description.trim()) {
      newErrors.description = "Description cannot be empty.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmitLoading(true);
      try {
        const payload = {
          milestoneId: selectedMilestone?.value,
          projectId,
          description,
          rating,
          feedbackId: requestId,
        };
        const response = await APIServices.post(
          "/project-milestone-feedback/give-feedback",
          payload
        );
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="form-group">
              <Select
                className={`form-control select2 ${
                  errors.milestone ? "is-invalid" : ""
                }`}
                options={mileStonesList}
                placeholder="Select Milestones"
                isClearable
                isLoading={loading}
                value={selectedMilestone}
                onChange={(option) => setSelectedMilestone(option)}
              />
              {errors.milestone && (
                <div className="invalid-feedback">{errors.milestone}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="form-group feedback-body">
            <p>Share Your Insights: Help Us Improve!</p>
              <textarea
                placeholder="Write your feedback here"
                className={`form-control ${
                  errors.description ? "is-invalid" : ""
                }`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <ul className="feedback-review d-flex">
                {[...Array(5)].map((_, index) => (
                  <li key={index} onClick={() => handleStarClick(index)}>
                    <img
                      src={feedbackReview}
                      alt={`Star ${index + 1}`}
                      className={`feedbackReview-img ${
                        index < rating ? "" : "opacity50"
                      }`}
                    />
                  </li>
                ))}
              </ul>
              {errors.description && (
                <div className="invalid-feedback">{errors.description}</div>
              )}
            </div>
          </div>
        </div>

        <button type="submit" className="btn-black">
          {submitLoading ? selectLoader(35) : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default AddFeedbackModal;
